// -----------------------------------------------------------------------------
// Init

const initializeGlobalNamespace = () => {
  let namespace;
  if (typeof window === 'object') {
    namespace = window.filestackInternals;
    if (!namespace) {
      namespace = {};
      window.filestackInternals = namespace;
    }
    if (!namespace.loader) {
      namespace.loader = {
        modules: {},
      };
    }
  }
  return namespace;
};

const filestackInternals = initializeGlobalNamespace();

// -----------------------------------------------------------------------------
// Modules loading

// All modules share global "register", so different instances of loader can
// communicate which modules were already loaded and which not.
const modules = filestackInternals && filestackInternals.loader.modules;

export const loadModule = (url, moduleId) => {
  let moduleDefinition = modules[url];
  if (!moduleDefinition) {
    modules[url] = {};
    moduleDefinition = modules[url];
  }

  if (moduleDefinition.instance) {
    return Promise.resolve(moduleDefinition.instance);
  }

  if (moduleDefinition.promise) {
    return moduleDefinition.promise;
  }

  const promise = new Promise((resolve, reject) => {
    const embedScript = () => {
      moduleDefinition.resolvePromise = resolve;
      const script = document.createElement('script');
      script.src = url;
      script.onerror = reject;
      if (moduleId) script.id = moduleId;
      document.body.appendChild(script);
    };

    const checkIfDomReady = () => {
      if (document.readyState === 'complete') {
        embedScript();
      } else {
        setTimeout(checkIfDomReady, 50);
      }
    };

    checkIfDomReady();
  });

  moduleDefinition.promise = promise;

  return promise;
};

export const registerReadyModule = (instance, moduleId) => {
  let thisScript;
  if (moduleId && document.getElementById(moduleId)) {
    thisScript = document.getElementById(moduleId);
  } else {
    const scriptTags = document.getElementsByTagName('script');
    thisScript = scriptTags[scriptTags.length - 1];
  }
  const url = thisScript.getAttribute('src');
  const moduleDefinition = modules[url];
  if (moduleDefinition && moduleDefinition.resolvePromise) {
    moduleDefinition.instance = instance;
    moduleDefinition.resolvePromise(instance);
    delete moduleDefinition.promise;
    delete moduleDefinition.resolvePromise;
  }
};

// -----------------------------------------------------------------------------
// CSS loading

export const loadCss = (url) => {
  const alreadyAddedThisTag = document.querySelector(`link[href="${url}"]`);
  if (alreadyAddedThisTag !== null) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');

    const loaded = () => {
      resolve();
      link.removeEventListener('load', loaded);
    };

    link.rel = 'stylesheet';
    link.href = url;
    link.addEventListener('load', loaded);
    head.appendChild(link);
  });
};

export const knownModuleIds = {
  picker: '__filestack-picker-module',
};
